import React, { useState, useEffect } from "react"
import { Box, Heading, Image, Text } from "theme-ui"
import { Link } from "gatsby"
import slugify from "../../helpers/slugify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import SortBy from "./Filter/SortBy"
import OrderBy from "./Filter/OrderBy"
import Search from "./Filter/Search"
import moment from "moment"
import teamStyles from "../../styles/articles/teamMenuStyles"
import generalStyles from "../../styles/articles/generalMenuStyles"
import pressStyles from "../../styles/articles/pressMenuStyles"
import policiesStyles from "../../styles/articles/policiesMenuStyles"
import blogStyles from "../../styles/articles/blogMenuStyles"
import TagsFilter from "./Filter/TagsFilter"
import Tags from "./ArticleComponents/Tags"
import cloudinaryOptimize from "../../helpers/cloudinaryOptimize"

export default function ArticleMenu({ articleData, pageObjectKey, blogTags }) {
  const [articleList, setArticleList] = useState(articleData.edges)
  const [articleFilterSettings, setArticleFilterSettings] = useState({
    search: "",
    order: "desc", // DESC or ASC
    sortBy: "createdAt",
    tags: [],
  })

  // the proeprties to compare are one level deeper with node and an child field of the object.
  function dynamicSort(key, order = "desc") {
    return function innerSort(a, b) {
      if (!a.node.hasOwnProperty(key) || !b.node.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0
      }
      // if the variable is a string lowercase if not don't other it will crash trying to lowercase a intergar
      const varA =
        typeof a.node[key] === "string"
          ? a.node[key].toLowerCase()
          : a.node[key]

      const varB =
        typeof b.node[key] === "string"
          ? b.node[key].toLowerCase()
          : b.node[key]

      // this is the sorting operator and moves element back or forward in the array to sort it in the right order
      let comparison = 0
      varA > varB ? (comparison = 1) : (comparison = -1)

      return order === "desc" ? comparison * -1 : comparison
    }
  }

  const updateArticleList = () => {
    // checks the articles to match the search criteria on subtitle and text only
    // opted not to do description to not slowdown the algorthrim
    const filteredData = articleData.edges.map(article => {
      const titleMatch = article?.node?.title
        ?.toLowerCase()
        ?.includes(articleFilterSettings.search)

      const subtitleMatch = article?.node?.subtitle
        ?.toLowerCase()
        ?.includes(articleFilterSettings.search)

      const tagMatch = articleFilterSettings.tags.filter(element =>
        article?.node?.tags.map(str => str.toLowerCase()).includes(element)
      )

      const noTagsSelected = articleFilterSettings.tags.length === 0

      const foundMatchingTags = tagMatch.length > 0

      const tagFilterMatch = noTagsSelected ? true : foundMatchingTags

      if ((titleMatch && tagFilterMatch) || (subtitleMatch && tagFilterMatch)) {
        return article
      }
    })

    // takes the searched data and filters it by sort by and order
    filteredData.sort(
      dynamicSort(articleFilterSettings.sortBy, articleFilterSettings.order)
    )

    // then set that data to state to display on the UI
    setArticleList(filteredData.filter(e => e)) // returns a list with undefineds in it so filter thoose out using filter
  }

  const styleSelector = () => {
    switch (pageObjectKey) {
      case "blog":
        return blogStyles

      case "team":
        return teamStyles

      case "press":
        return pressStyles

      case "general":
        return generalStyles

      case "policies":
        return policiesStyles

      default:
        break
    }
  }

  const variant = styleSelector()

  useEffect(() => {
    if (pageObjectKey !== "team") {
      updateArticleList() //* only apply to pages that are not team
    }
    return () => {}
  }, [articleFilterSettings])

  return (
    <Box sx={variant.container}>
      {pageObjectKey === "blog" && (
        <Box sx={variant.filters}>
          <Heading sx={variant.filtersTitle}>Filters</Heading>

          <Text
            sx={variant.numArticles}
          >{`Displaying ${articleList?.length} of ${articleData?.edges?.length}`}</Text>

          <Search
            variant={variant}
            setArticleFilterSettings={setArticleFilterSettings}
            value={articleFilterSettings.search}
            articleFilterSettings={articleFilterSettings}
          />
          <SortBy
            variant={variant}
            setArticleFilterSettings={setArticleFilterSettings}
            value={articleFilterSettings.sortBy}
            articleFilterSettings={articleFilterSettings}
          />
          <OrderBy
            variant={variant}
            setArticleFilterSettings={setArticleFilterSettings}
            articleFilterSettings={articleFilterSettings}
            value={articleFilterSettings.order}
          />
          {pageObjectKey === "blog" && (
            <TagsFilter
              variant={variant}
              setArticleFilterSettings={setArticleFilterSettings}
              articleFilterSettings={articleFilterSettings}
              value={articleFilterSettings.tags}
              tags={blogTags}
            />
          )}
        </Box>
      )}

      <Box sx={variant.boxesContainer} className="boxesContainer">
        {articleList
          .sort((a, b) => {
            const aTag =
              Array.isArray(a.node.tags) && typeof a.node.tags[0] === "string"
                ? a.node.tags[0]
                : 0
            const bTag =
              Array.isArray(b.node.tags) && typeof b.node.tags[0] === "string"
                ? b.node.tags[0]
                : 0
            return aTag - bTag
          })
          .map((article, index) => {
            // creates an link to the article
            let articleLink = `/${pageObjectKey}/${slugify(
              article.node.title ? article.node.title : ""
            )}`

            let isExternalLink = false
            const ctaLinkUrl = article?.node?.ctaLink?.url
            const hasNoBodyText =
              article?.node?.bodyPlainText?.length < 3 ? true : false

            // if the page is press and there is a url link and the body is empty just link to article instead of page.
            if (
              // pageObjectKey === "press" &&
              ctaLinkUrl &&
              hasNoBodyText
            ) {
              articleLink = article?.node?.ctaLink?.url
              isExternalLink = true
            }

            return (
              <Box
                sx={variant.box}
                key={`article${pageObjectKey}-${index}`}
                className="boxItem"
              >
                <Link
                  to={articleLink}
                  target={isExternalLink ? "_blank" : ""}
                  style={
                    hasNoBodyText && pageObjectKey === "team" // ? if the member has no body to show then don't let them click it to see the page.
                      ? { pointerEvents: "none" }
                      : {}
                  }
                >
                  {article.node?.media?.[0]?.cloudinaryId && (
                    <Box sx={variant.imageContainer} className="imageContainer">
                      <Box sx={variant.imageFill} className="imageFill" />
                      <Image
                        sx={variant.image}
                        src={cloudinaryOptimize(
                          `https://res.cloudinary.com/gonation/${article.node?.media[0].cloudinaryId}`
                        )}
                        className="image"
                      />
                    </Box>
                  )}
                  <Box sx={variant.textContent} className="textContent">
                    <Heading as="h3" className="title" sx={variant.title}>
                      {article.node.title}
                    </Heading>
                    <Heading as="h4" className="subtitle" sx={variant.subtitle}>
                      {article?.node?.subtitle?.length > 100
                        ? `${article.node.subtitle.substring(0, 100)}...`
                        : article.node.subtitle}
                    </Heading>

                    <Text as="p" className="text" sx={variant.text}>
                      {article.node.description}
                    </Text>

                    {/* {article.node.tags && (
                      <Tags tags={article.node.tags} variant={variant} />
                    )} */}

                    {article.node.author && (
                      <Text as="p" className="author" sx={variant.author}>
                        By {article.node.author}
                      </Text>
                    )}
                    {pageObjectKey !== "team" && (
                      <Box sx={variant.timeContainer} className="timeContainer">
                        <Text sx={variant.readingTime} className="readingTime">
                          <FontAwesomeIcon icon={faClock} />
                          {article.node.readingTimeText}
                        </Text>
                        <Text sx={variant.createdAt} className="createdAt">
                          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                          {moment(article.node.createdAt).format(
                            "DD MMM, YYYY"
                          )}
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Link>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}
