import React from "react"
import { Box, Heading, Image, Text } from "theme-ui"

export default function Tags({ tags, variant }) {
  return (
    <>
      {tags && (
        <Box sx={variant.tagsContainer} className="tagsContainer">
          {tags.map(tag => (
            <Text sx={variant.tagPill} className="tagPill">
              {tag}
            </Text>
          ))}
        </Box>
      )}
    </>
  )
}
