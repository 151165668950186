// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================
import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Box, Heading } from "theme-ui"

// ?===================================
// ?===  Local Components Imports  ====
// ?===================================
import Layout from "../components/Layout"

// ?===================================
// ?========  Style Imports  ==========
// ?===================================

import ArticleMenu from "../components/Articles/ArticleMenu"

// ?===================================
// ?======  Template Component  =======
// ?===================================

const ArticleMenuTemplate = props => {
  // ?==========================
  // ?====== Destructing  ======
  // ?==========================

  const { pageContext, data, location } = props

  // let pageTitle = pageContext.pageObjectKey

  console.log(data)

  // ?===================================================
  // ?==============   ON/OFF MOUNTING   ================
  // ?===================================================

  // useEffect(() => {
  //   console.log(props)
  //   console.log(pageContext)
  //   return () => {}
  // }, [])

  const articleDataSelector = pageObjectKey => {
    switch (pageObjectKey) {
      case "team":
        return "allTeam"

      case "press":
        return "allPress"

      case "general":
        return "allGeneral"

      case "policies":
        return "allPolicies"
      case "blog":
        return "allBlog"

      default:
        break
    }
  }

  console.log(data[articleDataSelector(pageContext.pageObjectKey)])

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================

  return (
    <Layout pageTitle={pageContext.pageTitle} hasTopShoutBanner>
      {data[articleDataSelector(pageContext.pageObjectKey)] ? (
        <ArticleMenu
          articleData={data[articleDataSelector(pageContext.pageObjectKey)]}
          pageObjectKey={pageContext.pageObjectKey}
          blogTags={data.blogTags.tags}
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading
            as="h2"
            sx={{ fontSize: "2rem", textTransform: "uppercase" }}
          >
            No {pageContext.pageTitle} At This Time
          </Heading>
        </Box>
      )}
    </Layout>
  )
}

export default ArticleMenuTemplate

// ?=====================================
// ?======= GraphQl page queries ========
// ?=====================================

export const query = graphql`
  query articleTemplateQuery {
    blogTags {
      tags
    }
    allBlog(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          author
          articleType
          body
          bodyHTML
          bodyPlainText
          createdAt
          description
          id
          media {
            name
            description
            cloudinaryId
          }
          tags
          title
          subtitle
          readingTimeText
          readingTime
          stats {
            rants
            raves
            shares
            followers
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
    allPress(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          subtitle
          title
          body
          bodyHTML
          bodyPlainText
          readingTime
          readingTimeText
          description
          createdAt
          articleType
          media {
            cloudinaryId
            description
            name
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
    allTeam {
      edges {
        node {
          id
          subtitle
          title
          body
          bodyHTML
          bodyPlainText
          readingTime
          readingTimeText
          description
          createdAt
          articleType
          tags
          media {
            cloudinaryId
            description
            name
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
    allGeneral(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          subtitle
          title
          body
          bodyHTML
          bodyPlainText
          readingTime
          readingTimeText
          description
          createdAt
          articleType
          media {
            cloudinaryId
            description
            name
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
  }
`
