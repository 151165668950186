import React from "react"
import { Select, Box, Label } from "theme-ui"

export default function SortBy({
  variant,
  setArticleFilterSettings,
  articleFilterSettings,
  value,
}) {
  return (
    <Box sx={variant.sortByContainer} className="sortByContainer">
      <Label sx={variant.filterLabel}>Sort By</Label>
      <Select
        value={value}
        onChange={e => {
          setArticleFilterSettings({
            ...articleFilterSettings,
            sortBy: e.target.value,
          })
        }}
      >
        <option value="title">Title</option>
        <option value="subtitle">Subtitle</option>
        <option value="createdAt">Date</option>
        <option value="readingTime">Reading Time</option>
      </Select>
    </Box>
  )
}
